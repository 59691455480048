import $ from 'jquery'
import 'select2';
import 'select2/dist/css/select2.css'

class ChampionSelect {

  constructor(elementSelector) {
    this.selectField = $(elementSelector)
  }

  formatState(state) {
    if (!state.id || !state.element.dataset['lolId']){
      return state.text;
    }

    var imgBasePath = "https://ddragon.leagueoflegends.com/cdn/13.1.1/img/champion/"
    var $state = $(
      '<span><img src="' + imgBasePath + state.element.dataset['lolId'] + '.png" class="champion-thumbnail" /> ' + state.text + '</span>'
    );
    return $state;
  }

  startChampionSelect() {
    this.selectField.select2({
      // theme: "foundation",
      templateResult: this.formatState,
      templateSelection: this.formatState,
      placeholder: 'Select a champion'
    })
  }

  bindSelection() {
    this.selectField.on('change', (event) => {
      var option = event.target.options[event.target.options.selectedIndex];
      var slug = option.dataset['slug'];

      if (slug) {
        window.location = '/league_of_legends/champions/' + slug;
      }
    })
  }

  start() {
    this.startChampionSelect();
    this.bindSelection()
  }
}

document.addEventListener('turbolinks:load', () =>
  (new ChampionSelect("#champion-select")).start()
) 

import $ from 'jquery'

import 'rateyo/lib/es/rateyo.css'
import RateYo from 'rateyo'

class StarRatingDiv {
  constructor(node) {
    this.node = node;
  }

  bindRating() {
    var element = $(this.node);

    var options = {
      starWidth: element.data('starWidth') || '25px',
      halfStar: true,
      numStars: 5,
      maxValue: 5,
      spacing: '10px',
      rating: element.data('rating') || 0,
      readOnly: element.data('readOnly')
    };

    if (element.data('inputId')) {
      options.onSet = (rating, rateYoInstance) => {
        $(`#${element.data('inputId')}`).val(rating);
      }
    }

    RateYo(this.node, options)
  }

  start() {
    this.bindRating();
  }
}

document.addEventListener('turbolinks:load', () =>
  $('.star-rating').each((index, element) => {
    var ratingDivElement = $(element);
    var ratingDiv = new StarRatingDiv(element);
    ratingDiv.start();
  })
)
